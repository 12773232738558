/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const PAYWALL_BUNDLE = 'https://d26q9q5kxy2g52.cloudfront.net/paywall/bundle.js'
const LOGIN_BUNDLE =
  'https://d26q9q5kxy2g52.cloudfront.net/login/static/js/bundle.js?v=31231'
const MY_PAGES_BUNDLE =
  'https://d26q9q5kxy2g52.cloudfront.net/my-pages/static/js/bundle.js'
const GALLERY_BUNDLE =
  'https://d26q9q5kxy2g52.cloudfront.net/gallery/static/bundle.js'

function jsFriendlyJSONStringify(s) {
  return JSON.stringify(s)
    .replace(/\u2028/g, '\\u2028')
    .replace(/\u2029/g, '\\u2029')
}

function addBundle(id, src) {
  const el = document.getElementById(id)

  if (el) {
    el.parentNode.removeChild(el)
  }

  let tag = document.createElement('script')
  tag.src = `${src}`
  tag.id = id

  document.body.appendChild(tag)
}

function addExternaBundles() {
  const pathname = window.location.pathname

  /* Paywall bundle */
  addBundle('pw', PAYWALL_BUNDLE)

  /* Login bundle */
  addBundle('lp', LOGIN_BUNDLE)

  /* Mypages bundle */
  if (document.getElementById('my-pages-root')) addBundle('ms', MY_PAGES_BUNDLE)

  /* Gallery bundle */
  if (document.getElementById('gallery-root')) addBundle('ga', GALLERY_BUNDLE)
}

exports.onInitialClientRender = () => {
  global.lrf_paywall_data = {
    siteCode: 'lantmannen',
    target: 'paywall-root',
    apiBaseUrl: 'https://payment.lrfmedia.io/',
    joinLRFLink: 'https://www.lrf.se/om-lrf/bli-medlem/',
    subscribeLink: 'https://prenumerera.lantmannen.nu',
    copy: jsFriendlyJSONStringify({
      lockerTitle: 'Välkommen till Lantmannens e-tidning!',
      lockerLoginLink: 'Klicka här',
      lockerLoginText: 'för att registrera konto och logga in',
      lockerLoginTextFirstPart: 'Är du prenumerant? Då ingår e-tidningen.',
      lockerList: [
        'Inspiration av professionella kollegor.',
        'Expertråd inom växtodling, teknik och gårdsekonomi.',
        'Följ med till världens fält och internationella marknader.',
      ],
      lockerOffer: 'Prenumerera på Lantmannen',
      lockerSubscribe: 'prenumerera nu',
    }),
  }
  global.lrf_my_pages_data = {
    siteCode: 'lantmannen',
    apiBaseUrl: 'https://login.lantmannen.nu',
    customerServicePhone: '010-184 40 84',
    customerServiceEmail: 'kundservice@lrfmedia.se',
    emailServiceUrl: 'https://email-service-production.functions.lrfmedia.io',
    emailServiceAuthToken:
      'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXJ2aWNlIjoiZW1haWwtc2VydmljZS1wcm9kdWN0aW9uIiwiaWF0IjoxNjAyNTczOTg2fQ.B8JKaIjLIohhfxcEPnSrnjj1m-X8fMxAkF5euMJuvhA',
  }
  global.lrf_login_data = {
    siteCode: 'lantmannen',
    apiBaseUrl: 'https://lmid.lantmannen.nu',
    customerServiceLink: 'https://www.lantmannen.nu/kontakt',
    styles: {
      main: 'Login',
      button: 'Login-button',
      buttonLogInOut: 'Login-button Login-button--logInOut',
      buttonWithDropdown: 'Login-button Login-button--expandable',
      dropdown: 'Login-dropdown',
      isDropdownOpen: 'is-open',
      isAuthenticated: 'is-active',
      modal: 'Login-modal',
    },
    subscribeLink: 'https://prenumerera.lantmannen.nu/',
    myPagesLink: 'https://minasidor.lantmannen.nu',
  }
  global.lrf_gallery_data = {
    siteCode: 'lantmannen',
  }
}

exports.onRouteUpdate = ({ location }) => {
  const targetNode = document.body

  const attributeCheck = (mutationsList, observer) => {
    for (let mutation of mutationsList) {
      if (mutation.attributeName === 'data-prop-articlelevel') {
        if (
          mutation.oldValue !==
          targetNode.getAttribute('data-prop-articlelevel')
        ) {
          if (window.store) window.store.unsubscribeAll()

          addExternaBundles()
          observer.disconnect()
        }
      }
    }
  }

  const observer = new MutationObserver(attributeCheck)

  observer.observe(targetNode, { attributes: true })
}
